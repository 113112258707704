import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPopups, PopupsSymbol } from './index'
import { ILinkClickHandler } from '@wix/thunderbolt-symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

const popupLinkFactory = (popupApi: IPopups, urlHistoryManager: IUrlHistoryManager): ILinkClickHandler => ({
	handleClick: (anchor) => {
		const popupId = anchor.getAttribute('data-popupid')
		if (popupId) {
			popupApi.openPopupPage(popupId)
			return true
		}
		const relativeUrl = urlHistoryManager.getRelativeUrl()
		const isLightboxOpen = !!popupApi.getCurrentPopupId()
		const isLinkToCurrentPage = relativeUrl === anchor.getAttribute('href')
		if (isLightboxOpen && isLinkToCurrentPage) {
			popupApi.closePopupPage()
			return true
		}

		return false
	},
})

export const PopupLink = withDependencies([PopupsSymbol, UrlHistoryManagerSymbol], popupLinkFactory)
